import { Component, Injector, Type } from '@angular/core';
import { ServiceLocator } from 'src/app/app.module';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';

@Component({
	selector: 'app-base',
	template: ''
})
export class BasePage {
	language = 'pt';
	redirect: string = null;

	protected loadingService: LoadingService;
	protected toastService: ToastService;
	protected translateService: TranslateService;
	protected router: Router;
	protected alertController: AlertController;

	constructor(public navCtrl?: NavController) {
		const injector: Injector = ServiceLocator.injector;
		this.loadingService = injector.get<LoadingService>(LoadingService as Type<LoadingService>);
		this.toastService = injector.get<ToastService>(ToastService as Type<ToastService>);
		this.translateService = injector.get<TranslateService>(TranslateService as Type<TranslateService>);
		this.router = injector.get<Router>(Router as Type<Router>);
		this.alertController = injector.get<AlertController>(AlertController as Type<AlertController>);
	}

	translate(word: string) {
		return this.translateService.instant(word);
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}
}
